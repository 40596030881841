/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView {
  /* EVENT GRID */
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer {
  overflow: hidden;
  left: 0;
  right: 0;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner {
  -moz-transition: 1s all;
  -o-transition: 1s all;
  -webkit-transition: 1s all;
  transition: 1s all;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket {
  color: #fff;
  float: left;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item {
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 20px;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item .slideshowimage {
  width: 100%;
  padding-top: 25px;
  /*filter: grayscale(100%);
                                -webkit-filter: grayscale(100%);
                                -moz-filter: grayscale(100%);
                                -ms-filter: grayscale(100%);
                                -o-filter: grayscale(100%);
                                filter: url(resources.svg#desaturate);
                                filter: gray;
                                -webkit-filter: grayscale(1);*/
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item .slideshowimage:hover {
  /*filter: none;
                                    -webkit-filter: none;
                                    -moz-filter: none;
                                    -ms-filter: none;
                                    -o-filter: none; 
                                    filter: none;
                                    filter: none;
                                    -webkit-filter: none;*/
  cursor: pointer;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item .slideshowtext {
  color: black;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item:hover {
  cursor: pointer;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item .altai-badge {
  float: left;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item .altai-slideshowgrid-content {
  /*margin-top: @grid-item-height/3;*/
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item .altai-slideshowgrid-content .altai-slideshowgrid-item-title {
  font-family: Campton;
  font-weight: 600;
  padding-bottom: 20px;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item .altai-slideshowgrid-content .altai-slideshowgrid-item-tagline {
  font-family: Campton;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-inner .altai-slideshowgrid-bucket .altai-slideshowgrid-item .altai-slideshowgrid-content .altai-slideshowgrid-item-date {
  font-family: Campton;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-left {
  left: 50px;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-right {
  right: 50px;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-left,
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-right {
  position: absolute;
  background-color: transparent;
  color: black;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-left:focus,
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-right:focus {
  outline: none;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-left:hover,
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-right:hover {
  color: #19d1c6;
  cursor: pointer;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-indicators {
  position: absolute;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-indicators .altai-slideshowgrid-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: transparent;
  margin-right: 5px;
  display: inline-block;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-indicators .altai-slideshowgrid-indicator:focus {
  outline: none;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-indicators .altai-slideshowgrid-indicator.selected {
  background-color: white;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-indicators .altai-slideshowgrid-pauseplay {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 5px;
  display: inline-block;
  color: white;
  font-size: 14px;
}
.altai-theme-content #bodyView altai-slideshow-grid-two-by-two .altai-slideshowgridtwobytwo-outer .altai-slideshowgrid .altai-slideshowgrid-indicators .altai-slideshowgrid-pauseplay:focus {
  outline: none;
}
.altai-theme-content #bodyView .altai-slideshowgrid-item.Avondconferentie {
  background-color: #19d1c6;
}
.altai-theme-content #bodyView .altai-slideshowgrid-item.Cursus {
  background-color: #00B4E1;
}
.altai-theme-content #bodyView .altai-slideshowgrid-item.Workshop {
  background-color: #9677E5;
}
.altai-theme-content #bodyView #altai-slideshowgrid-noresults {
  padding: 50px;
  text-align: center;
  font-size: 28px;
}
